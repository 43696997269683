<mat-toolbar id="top-toolbar" class="top-toolbar bg-nav">
  <div
    class="theme-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <app-contacts fxLayout="row" fxLayoutAlign="start center"></app-contacts>
    </div>
    <app-social-icons
      fxLayout="row"
      fxLayoutAlign="start center"
      fxShow="true"
      fxShow.gt-md
    ></app-social-icons>
  </div>
</mat-toolbar>
<mat-toolbar id="main-toolbar" class="mat-elevation-z2">
  <div
    class="theme-container"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <a class="logo" routerLink="/"><app-logo></app-logo></a>
    <!-- <div fxLayout="row" fxLayoutAlign="start center">
      <button (click)="openCart()" mat-icon-button matTooltip="Cart list">
        <mat-icon
          [matBadge]="appService.Data.totalCartCount"
          [matBadgeHidden]="appService.Data.totalCartCount == 0"
          matBadgeSize="small"
          matBadgeColor="primary"
          >shopping_cart</mat-icon
        >
      </button>
    </div> -->
  </div>
</mat-toolbar>
