<footer>
  <div class="px-3">
    <div class="theme-container">
      <div class="py-5 content border-lighter">
        <div fxLayout="row wrap" fxLayoutAlign="space-around center">
          <div fxFlex="30" fxFlex.xs="50" ngClass.lt-md="pt-2" class="mb-5">
            <a
              fxLayout="row"
              fxLayoutAlign="start enter"
              fxLayoutAlign.xs="center center"
              class="logo "
              routerLink="/"
              >
              <div>
                <app-logo></app-logo>
              </div>
           </a>
          </div>
          <div fxFlex="40" fxFlex.xs="100" class="mb-5">
            <p
              fxLayout="row"
              fxLayoutAlign="end enter"
              fxLayoutAlign.xs="center center"
              class="mt-2"
            >
              <span class="mx-2">Digital Menu, Egypt</span>
              <mat-icon color="primary">location_on</mat-icon>
            </p>
            <p
              fxLayout="row"
              fxLayoutAlign="end center"
              fxLayoutAlign.xs="center center"
              class="mt-1"
            >
              <span class="mx-2">01270888338</span>
              <mat-icon color="primary">call</mat-icon>
            </p>
            <p
              fxLayout="row"
              fxLayoutAlign="end center"
              fxLayoutAlign.xs="center center"
              class="mt-1"
            >
              <span class="mx-2"> Every day of the week / 9:00AM - 2:00AM</span>
              <mat-icon color="primary">schedule</mat-icon>
            </p>
          </div>
        </div>
        <app-social-icons
          [iconSize]="'lg'"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="desc"
        ></app-social-icons>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-between center"
        class="copyright py-1 text-center"
      >
        <p ngClass.xs="mt-1">Copyright © 2023 All Rights Reserved</p>
        <p>
          Designed & Developed by
          <a
            mat-button
            href="https://www.facebook.com/D.Menuss"
            color="primary"
            target="_blank"
            >Digital Menu</a
          >
        </p>
      </div>
    </div>
  </div>
</footer>
